import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  role: '',
  name: '',
  isTwoFA: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setupProfile: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.isTwoFA = action.payload.isTwoFA;

      return state;
    },
    resetProfile: (state, action) => {
      state = initialState;
      return state;
    },
  },
});

export const { resetProfile, setupProfile } = profileSlice.actions;
export default profileSlice.reducer;
