import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  role: 'subadmin',
  restriction: {
    modules: [],
    subModules: []
  },
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setupRoles: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setupRoles } = roleSlice.actions;
export default roleSlice.reducer;
