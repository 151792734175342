import { combineReducers } from 'redux';

import authReducer from './slices/authReducer';
import changeReducer from './slices/changeReducer';
import roleReducer from './slices/roleReducer';
import dateFilterReducer from './slices/dateFilterReducer';
import toastReducer from './slices/toastReducer';
import profileReducer from './slices/profileReducer';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducers = combineReducers({
    auth: authReducer,
    changeState: changeReducer,
    toast: toastReducer,
    role: roleReducer,
    DateFilter: dateFilterReducer,
    profile: profileReducer
  });
  return combinedReducers(state, action);
};

export default rootReducer;
