import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  message: '',
  type: '',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (state, action) => {
      state = action.payload.toast;
      return state;
    },
    resetToast: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { openToast, resetToast } = toastSlice.actions;
export default toastSlice.reducer;
