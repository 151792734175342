import axios, { handleResponse } from 'src/config/axios';
import { setupProfile } from 'src/redux/slices/profileReducer';
import { setupRoles } from 'src/redux/slices/roleReducer';

export const getAdminData = async (dispatch) => {
  try {
    let response = await axios({
      url: '/v1/admin/getAdminDetail',
      method: 'GET',
    });
    dispatch(
      setupRoles({
        role: response.data.result.role,
        restriction: {
          modules: response.data.result.modules,
          subModules: response.data.result.subModules,
        },
      }),
    );
    dispatch(
      setupProfile({
        name: response.data.result.name,
        email: response.data.result.email,
        role: response.data.result.role,
        isTwoFA: response.data.result.isTwoFA,
      }),
    );
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const login = async (data) => {
  try {
    let response = await axios({
      url: '/auth/admin/signin',
      method: 'POST',
      data,
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const updateAdminPassword = async (data) => {
  try {
    let response = await axios({
      url: '/auth/admin/change-password',
      method: 'POST',
      data,
    });

    return handleResponse(response);
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const get2FASetup = async () => {
  try {
    let response = await axios({
      url: '/auth/admin/two-factor-auth',
      method: 'GET',
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const enable2FA = async (data) => {
  try {
    let response = await axios({
      url: '/auth/admin/two-factor-auth',
      method: 'POST',
      data,
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const disable2FA = async (data) => {
  try {
    let response = await axios({
      url: '/auth/admin/two-factor-auth',
      method: 'PUT',
      data,
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
