let key = {};

if (process.env.REACT_APP_MODE === 'production') {
  console.log('Running in Production Mode');
  key = {
    API_URL: 'https://prodapi.aeroversepay.com/api',
    getGeoInfo: 'https://ipapi.co/json/',
    IMAGE: {},
  };
} else if (process.env.REACT_APP_MODE === 'demo') {
  console.log('Running in Demo Mode');
  key = {
    API_URL: 'https://aeroversepayapi.wearedev.team/api',
    getGeoInfo: 'https://ipapi.co/json/',
    IMAGE: {},
  };
} else {
  console.log('Running in Development Mode');
  key = {
    API_URL: 'http://localhost:5002/api',
    getGeoInfo: 'https://ipapi.co/json/',
    IMAGE: {},
  };
}

export default key;
