import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDate: false,
  filters: [],
};

const dateFilterSlice = createSlice({
  name: 'dateFilter',
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetFilter: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { resetFilter, updateFilter } = dateFilterSlice.actions;
export default dateFilterSlice.reducer;
