import { jwtDecode } from 'jwt-decode';
import { setupAuthentication } from 'src/redux/slices/authReducer';

export const decodeJwt = (token, dispatch) => {
  if (token) {
    token = token.replace('Bearer ', '');
    const decoded = jwtDecode(token);
    console.log(decoded)
    dispatch(setupAuthentication({ ...decoded, isAuth: true }));
    return decoded;
  }
};
