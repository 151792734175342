import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

const changeSlice = createSlice({
  name: 'change',
  initialState,
  reducers: {
    updateChange: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { updateChange } = changeSlice.actions;
export default changeSlice.reducer;
