// import package
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isLogin } from 'src/lib/localStorage';
// import action
import { getAdminData } from 'src/api/auth';
const HelperRoute = () => {
  const dispatch = useDispatch();
  const callOnce = useRef(true);

  // function
  useEffect(() => {
    if (isLogin()) {
      if (callOnce.current) {
        callOnce.current = false;
        getAdminData(dispatch);
      }
    }
  }, [dispatch]);

  return <React.Fragment />;
};

export default HelperRoute;
