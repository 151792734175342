import axios from 'axios';

import config from './index';
import { getAuthToken } from 'src/lib/localStorage';
import paramsEncoder from 'src/lib/paramEncoder';

axios.defaults.baseURL = config.API_URL;
axios.defaults.paramsSerializer = paramsEncoder;
axios.defaults.headers.common['Authorization'] = getAuthToken();
axios.defaults.headers.common['TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
// axios.defaults.headers.common['TIMESTAMP'] = new Date().toISOString()

axios.interceptors.request.use((config) => {
  config.headers['TIMESTAMP'] = new Date().toISOString();
  return config;
});

export const setAuthorization = (token) => {
  axios.defaults.headers.common['Authorization'] = token;
};

export const handleResponse = (responseData, type = 'success', doc = false) => {
  try {
    if (doc && type === 'success' && responseData && responseData.data) {
      return { data: responseData.data };
    }
    if (type === 'success' && responseData && responseData.data) {
      return responseData.data;
    } else if (
      type === 'error' &&
      responseData &&
      responseData.response &&
      responseData.response.data
    ) {
      return responseData.response.data;
    } else {
      return {
        success: false,
        message: 'Something went wrong',
      };
    }
  } catch (error) {
    return {
      success: false,
      message: 'Something went wrong',
    };
  }
};

export default axios;
